<template>
  <b-row>
    <b-col sm="12" md="8">
      <my-card :isloading="cardloading">
        <template slot="body">
          <form-validation @submit="submitForm">
            <b-row>
              <b-col sm="12">
                <form-select ref="regionalSelect" @value-changed="regionalChange" :rules="{required: !branch}" v-model="id_regional" label="Regional" url="v1/regional_select2"></form-select>
              </b-col>
              <b-col sm="12">
                <form-select-new multiple :rules="{required: !id_regional}" v-model="branch" label="Cabang" :queryparams="{id_regional: id_regional}" url="v1/branch_select2"></form-select-new>
              </b-col>
              <b-col sm="12">
                <form-input :rules="{required: true}" v-model="username" label="Username"></form-input>
              </b-col>
              <b-col sm="12">
                <form-input :rules="{required: true}" v-model="name" label="Nama Lengkap"></form-input>
              </b-col>
              <b-col sm="12">
                <form-input :rules="{required: true, email: true}" v-model="email" label="Email"></form-input>
              </b-col>
              <b-col sm="12" :hidden="id">
                <form-input type="password" :rules="{required: true, confirmed:'confirmed'}" v-model="password" label="Password"></form-input>
              </b-col>
              <b-col sm="12" :hidden="id">
                <form-input type="password" vid="confirmed" :rules="{required: true}" v-model="confirmed" label="Konfirmasi Password"></form-input>
              </b-col>
              <b-col sm="12">
                <form-select ref="groupSelect" @value-changed="groupChange" :rules="{required: true}" v-model="id_group" label="Jabatan" url="v1/user_group_select2"></form-select>
              </b-col>
              <b-col sm="12">
                <form-input v-model="note" label="Keterangan"></form-input>
              </b-col>
              <b-col sm="12">
                <b-form-group>
                  <b-form-checkbox v-model="is_active" switch>{{is_active ? 'Aktif' : 'Tidak Aktif'}}</b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col sm="12" class="mt-3">
                <button-save :isloading="isloading"></button-save>
                <button-back class="ml-1" :to="{name:'users'}"></button-back>
              </b-col>
            </b-row>
          </form-validation>
        </template>
      </my-card>
    </b-col>
  </b-row>
</template>

<script>
import FormInput from '@/my-components/FormInput.vue'
import ButtonSave from '@/my-components/ButtonSave.vue'
import ButtonBack from '@/my-components/ButtonBack.vue'
import { mapActions } from 'vuex'
import FormValidation from '@/my-components/FormValidation.vue'
import FormSelect from '@/my-components/FormSelect.vue'
import FormSelectNew from '@/my-components/FormSelectNew.vue'
import { BFormGroup,BFormCheckbox } from 'bootstrap-vue'
import _ from 'lodash'

export default {
  components:{
    FormInput,
    ButtonSave,
    ButtonBack,
    FormValidation,
    FormSelect,
    FormSelectNew,
    BFormGroup,
    BFormCheckbox
  },
  data(){
    return {
      id: this.$route.params.id||null,
      id_regional: null,
      branch: [],
      regional_name:'',
      branch_name:'',
      username:'',
      name:'',
      email:'',
      tlp:'',
      password:'',
      confirmed:'',
      note:'',
      id_group:null,
      group_name:null,
      is_active:true,
      isloading:false,
      cardloading: false
    }
  },
  methods:{
    ...mapActions({
      dispatchStore: 'users/store',
      dispatchShow: 'users/show',
      dispatchUpdate: 'users/update',
    }),
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    regionalChange(json){
      this.branch = []
      if(json.label) this.regional_name = json.label;
      else this.regional_name = '';
    },
    branchChange(json){
      if(json.label) this.branch_name = json.label;
      else this.branch_name = '';
    },
    groupChange(json){
      if(json.label) this.group_name = json.label;
      else this.group_name = '';
    },
    async getData(){
      this.cardloading = true
      const data = await this.dispatchShow(this.id)
      this.id_regional = data.id_regional
      const branch = _.map(data.user_branches,n => {
        return {
          value: n.id_branch,
          label: n.branch_name
        }
      })
      this.branch = branch
      this.regional_name = data.regional_name
      this.username = data.username
      this.name = data.name
      this.email = data.email
      this.tlp = data.tlp
      this.password = data.password
      this.confirmed = data.password
      this.note = data.note
      this.id_group = data.id_group
      this.group_name = data.group_name
      this.is_active = data.is_active ? true : false
      if (data.id_regional) this.$refs.regionalSelect.initOption([{"value":data.id_regional,"label":data.regional_name}])
      if (data.id_group) this.$refs.groupSelect.initOption([{"value":data.id_group,"label":data.group_name}])
      this.cardloading = false
    },
    async submitForm(){
      this.isloading = true
      const {id,id_regional,regional_name,username,name,email,tlp,password,note,id_group,group_name,is_active} = this
      let params = {id,id_regional,regional_name,username,name,email,tlp,note,id_group,group_name,is_active}
      if(this.branch) {
        params.id_branch = _.map(this.branch,'value')
        params.branch_name = _.map(this.branch,'label')
      }
      if(!this.id) params['password'] = password;

      try {
        if (this.id) await this.dispatchUpdate(params);
        else await this.dispatchStore(params);
        this.toastSuccess("OK","Data berhasil disimpan!")
        this.$router.push({name:'users'})
      } catch (error) {
        this.handleError(error)
      }
      this.isloading = false
    },
  },
  mounted(){
    if(this.id) return this.getData();
  }
}
</script>

<style>

</style>